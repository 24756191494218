<template>
  <div>
    <template v-if="entryToShow.study_uid != ''">
      <b-badge class="d-none d-sm-block overflow-hidden" variant="info" show :size="buttonSize">{{ title }}</b-badge>
    </template>
    <b-navbar v-if="entryToShow.study_uid != ''" type="dark" variant="dark">
      <span class="ml-2 font-weight-bold text-light">Patient History</span>
      <b-navbar-nav class="ml-auto">
        <b-button-group :size="buttonSize">
          <b-button @click="handleBack" variant="secondary" title="Back to Patient History">
            <b-icon icon="folder-symlink"></b-icon>
          </b-button>
          <b-button class="ml-2" @click="handleClose" variant="secondary" title="Close Patient History">
            <b-icon icon="file-x"></b-icon>
          </b-button>
        </b-button-group>
      </b-navbar-nav>
    </b-navbar>
    <div ref="alertsDiv">
      <template v-if="entryToShow.study_uid != ''">
        <b-alert v-if="!documentError && (documentBuffer == '')" class="mt-2" variant="info" show>Loading document...</b-alert>
        <b-alert v-if="documentError" class="mt-2" variant="warning" show>
          Error loading document.
          <b-button class="ml-2" variant="primary" @click="show()">Retry</b-button>
        </b-alert>
      </template>
    </div>
    <div :style="pdfParentDivStyle">
      <div class="mt-1 pdfDiv" >
        <template v-for="i in pageCount">
          <div :key="i">
            <b-badge variant="secondary" show size="sm">Page {{i}}</b-badge>
            <pdf :src="documentBuffer" :page="i"></pdf>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import webServices from '../common/webServices'
import pdf from 'vue-pdf'

export default {
  name: 'pdfViewer',
  components: {
    pdf,
  },
  data() {
    return {
      buttonSize: "sm",
      currentPage: 1,
      entryToShow: webServices.getEmptyWorklistEntry(),
      requestedPage: 1,
      pageCount: 0,
      documentBuffer: '',
      documentId: '',
      documentError: false,
      viewerHeight: '100px'
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize()
  },
  computed: {
    activeStudyUid() {
      return (this.$store.state.activeComponent == 'PdfViewer') ? this.$store.state.activeStudyUid : ''
    },
    entry() {
      var entry = this.$store.getters.worklistEntryForStudy(this.activeStudyUid)
      entry = (entry != null) ? entry : webServices.getEmptyWorklistEntry()
      return entry
    },
    pdfParentDivStyle() {
      return "height: "+this.viewerHeight+"px;"
    },
    title() {
      return this.entryToShow.patient_name+' (MRN: '+this.entryToShow.patient_id+') (Tenant: '+this.entryToShow.group+')'
    }
  },
  watch: {
    entry(newVal/*, oldval*/) {
      // Patient/study information may have changed in entry.
      //
      if (this.$store.state.activeComponent == 'PdfViewer') {
        if ((newVal != null) && (newVal.study_uid == this.entryToShow.study_uid)) {
          this.entryToShow = newVal
        }
      }
    },
    activeStudyUid(newVal/*, oldVal*/) {
      if ((this.$store.state.activeComponent == 'PdfViewer')) {
        if (newVal != this.entryToShow.study_uid) {
          this.$store.commit('changeActiveComponent', 'PatientHistory')
        }
      }
      else {
        this.entryToShow = webServices.getEmptyWorklistEntry()
      }
    }
  },
  methods: {
    show() {
      this.documentBuffer = ''
      this.requestedPage = 1
      this.documentId = this.$store.state.patientHistoryDocId
      this.documentError = false
      this.pageCount = 0
      this.entryToShow = this.entry

      // Load PDF for patient history document
      //
      this.$log.debug('PdfViewer getting document for docId='+this.$store.state.patientHistoryDocId)
      webServices.readPatientHistoryDocument(this.entry, this.$store.state.patientHistoryDocId)
      .then((response) => {
        if (response != null) {
          this.$log.debug("Read document.")
          this.documentBuffer = pdf.createLoadingTask({ data: response })
          this.documentBuffer.promise.then(pdf => {
            this.pageCount = pdf.numPages
            this.handleResize()
          })
        }
        else {
          this.$log.debug("Document null or empty.")
          this.documentError = true
          this.handleResize()
        }
      })
      .catch(err => {
        let retryTimeout = 2000
        this.$log.warn("Error fetching document, will retry in "+retryTimeout/1000+" seconds, err=: "+err.message)
        if (this.documentId == this.$store.state.patientHistoryDocumentId) {
          var documentId = this.documentId
          setTimeout(() => {
            if (documentId == this.$store.state.patientHistoryDocumentId) {
              this.show()
            }
          }, retryTimeout);
        }
        else {
          this.$log.debug("Skipping retry for PDF")
        }
      })
    },
    handleBack() {
      this.$store.commit('changeActiveComponent', 'PatientHistory')
    },
    handleClose() {
      this.$store.commit('changeActiveComponent', '')
    },
    handleResize(/*event*/) {
      if (this.documentError || (this.documentBuffer == '')) {
        this.viewerHeight = 0
      }
      else {
        this.viewerHeight = window.innerHeight - 175
      }
    }
  }
};
</script>
<style scoped>
.pdfDiv {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>