<template>
  <div>
    <template v-if="entryToShow.study_uid != ''">
      <b-badge class="d-none d-sm-block overflow-hidden" variant="info" show :size="buttonSize">{{ title }}</b-badge>
    </template>
    <b-navbar v-if="entryToShow.study_uid != ''" type="dark" variant="dark">
      <span class="ml-2 font-weight-bold text-light">Patient History</span>
      <b-navbar-nav class="ml-auto">
        <b-button-group :size="buttonSize">
          <b-button @click="handleBack" variant="secondary" title="Back to Patient History">
            <b-icon icon="folder-symlink"></b-icon>
          </b-button>
          <b-button class="ml-2" @click="handleClose" variant="secondary" title="Close Patient History">
            <b-icon icon="file-x"></b-icon>
          </b-button>
        </b-button-group>
      </b-navbar-nav>
    </b-navbar>
    <div class="imageDiv w-100" :style="imageDivStyle">
      <b-img v-if="imageSrc != null" class="w-100" v-bind="imageProps" :src="imageSrc" rounded alt="Patient History Image"></b-img>
    </div>
  </div>
</template>

<script>
import webServices from '../common/webServices'

export default {
  name: 'imageViewer',
  components: {
  },
  data() {
    return {
      buttonSize: "sm",
      entryToShow: webServices.getEmptyWorklistEntry(),
      imageSrc: null,
      imageProps: { blank: false, class: 'm1' },
      viewerHeight: "100px"
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize()
  },
  computed: {
    activeStudyUid() {
      return (this.$store.state.activeComponent == 'ImageViewer') ? this.$store.state.activeStudyUid : ''
    },
    entry() {
      var entry = this.$store.getters.worklistEntryForStudy(this.activeStudyUid)
      entry = (entry != null) ? entry : webServices.getEmptyWorklistEntry()
      return entry
    },
    imageDivStyle() {
      return "height: "+this.viewerHeight+"px;"
    },
    title() {
      return this.entryToShow.patient_name+' (MRN: '+this.entryToShow.patient_id+') (Tenant: '+this.entryToShow.group+')'
    }
  },
  watch: {
    entry(newVal/*, oldval*/) {
      // Patient/study information may have changed in entry.
      //
      if (this.$store.state.activeComponent == 'ImageViewer') {
        if ((newVal != null) && (newVal.study_uid == this.entryToShow.study_uid)) {
          this.entryToShow = newVal
        }
      }
    },
    activeStudyUid(newVal/*, oldVal*/) {
      if ((this.$store.state.activeComponent == 'ImageViewer')) {
        if (newVal != this.entryToShow.study_uid) {
          this.$store.commit('changeActiveComponent', 'PatientHistory')
        }
      }
      else {
        this.entryToShow = webServices.getEmptyWorklistEntry()
      }
    }
  },
  methods: {
    show() {
      // Load image for patient history document
      //
      this.imageSrc = null
      this.entryToShow = this.entry
      webServices.readPatientHistoryDocument(this.entryToShow, this.$store.state.patientHistoryDocId)
      .then((response) => {
        const blob = new Blob([response])
        this.imageSrc = URL.createObjectURL(blob);
      })
      .catch(err => {
        this.$log.error("Error fetching image: "+err)
        this.simageSrc = null
      })
    },
    handleBack() {
      this.$store.commit('changeActiveComponent', 'PatientHistory')
    },
    handleClose() {
      this.$store.commit('changeActiveComponent', '')
    },
    handleResize(/*event*/) {
      this.viewerHeight = window.innerHeight - 175
    }
  }
};
</script>
<style scoped>
.imageDiv {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>