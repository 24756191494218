<template>
  <div>
    <b-modal ref="warningDialog" title="Updates Not Saved"
      header-bg-variant="warning" header-text-variant="dark"
      body-bg-variant="dark" body-text-variant="light"
      footer-bg-variant="dark" footer-text-variant="light"
      content-class="shadow" @ok="handleWarningOK" ok-only>
      <span v-html="warningMsg"></span>
    </b-modal>
    <a ref="documentLauncher" target="patientHistory"></a>
    <template v-if="entryToShow.study_uid != ''">
      <b-badge class="d-none d-sm-block overflow-hidden" variant="info" show size="sm">
        <b-icon v-if="entry.stat" scale="1.0" icon="patch-exclamation-fill" class="text-danger mr-1"></b-icon>
        <b-icon v-if="entry.read" scale="1.0" icon="check-circle-fill"></b-icon>
        &nbsp;
        {{ title }}
      </b-badge>
    </template>
    <b-navbar v-if="entryToShow.study_uid != ''" type="dark" variant="dark">
      <span class="ml-2 font-weight-bold text-light"></span>
      <b-navbar-nav class="ml-auto">
        <b-button-group :size="buttonSize">
          <b-button class="ml-2" @click="handleClose" variant="secondary" title="Close">
            <b-icon icon="file-x"></b-icon>
          </b-button>
        </b-button-group>
      </b-navbar-nav>
    </b-navbar>
    <b-list-group v-if="entryToShow.study_uid != ''" >
      <b-list-group-item v-if="showPatientHistory && (canViewPatientHistory || canEditPatientHistory)" class="bg-dark text-light">
        <b-button variant="secondary" size="sm" @click="showDocuments=!showDocuments" :showDocuments="showDocuments" v-b-toggle.collapse-docs>{{showDocuments ? '−' : '+'}}</b-button>
        <span class="ml-2 font-weight-bold">Patient History</span> <b-badge variant="info" pill>{{documentItems.length}}</b-badge>
        <b-collapse id="collapse-docs" visible>
          <b-table class="mt-2 small" striped head-variant="dark" table-variant="secondary" :items="documentItems" :fields="documentFields" :sort-by.sync="docSortBy" :sort-desc.sync="docSortDesc" :sort-compare="sortCompare" primary-key="date_time">
            <template #cell(actions)="data">
              <b-button-group aria-label="Actions" size="sm">
                <b-button v-if="canEditPatientHistory && !data.item.locked" variant="secondary" title="Delete" @click="actionDeleteDocument(data.item)"><b-icon icon="trash"></b-icon></b-button>
                <b-button class="ml-1" variant="secondary" title="View" @click="actionViewDocument(data.item)"><b-icon icon="binoculars"></b-icon></b-button>
              </b-button-group>
            </template>
            <template #cell(description)="data">
              {{data.item.description}} ({{data.item.filetype}})
            </template>
            <template #cell(timestamp)="data">
              {{new Date(data.item.timestamp).toLocaleString(locale)}}
            </template>
          </b-table>
        </b-collapse>
      </b-list-group-item>
      <b-list-group-item v-if="showPatientHistory && canEditPatientHistory" class="bg-dark text-light">
        <b-form>
          <b-row class="mt-1">
            <b-col>
              <b-form-group label="New Document(s):" label-for="document_file"  label-cols="3">
                <b-form-file
                  v-model="documentFiles"
                  id="document_file"
                  :disabled="isUploading"
                  :state="Boolean(documentFiles.length>0)"
                  :accept="acceptTypes"
                  placeholder="Choose file(s) or drop here..."
                  multiple
                  drop-placeholder="Drop file(s) here..."/>
                <b-form-input id="document_desc" :disabled="isUploading" :state="Boolean(1)" v-model="documentDesc" placeholder="Enter optional description..."/>
                <b-form-text id="input-live-help">File size limited to {{uploadLimitMb}} MB.&nbsp;
                  <b-badge v-if="!documentFileSizesOkay" variant="warning">One or more files exceed this limit.</b-badge>
                  </b-form-text>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="6">
              <b-progress v-if="isUploading" :max="documentFileSize" show-progress>
                <b-progress-bar variant="info" :value="documentFileUploaded"></b-progress-bar>
              </b-progress>
            </b-col>
            <b-col cols="6">
              <div class="float-right">
                <b-button @click="uploadDocuments" :disabled="isUploading || (documentFiles.length == 0) || !documentFileSizesOkay" variant="secondary">
                  <b-spinner v-if="isUploading" small></b-spinner><span v-if="isUploading">&nbsp;Uploading {{documentFileI + 1}} of {{documentFiles.length}}...</span>
                  <span v-if="!isUploading">Upload New Document(s)</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-list-group-item>
      <b-list-group-item v-if="showStudyNotes && (canViewNotes || canEditNotes)" class="bg-dark text-light">
        <b-button variant="secondary" size="sm" @click="showNotes=!showNotes" :showNotes="showNotes" v-b-toggle.collapse-notes>{{showNotes ? '−' : '+'}}</b-button>
        <span class="ml-2 font-weight-bold">Notes</span> <b-badge variant="info" pill>{{noteItems.length}}</b-badge>
        <b-collapse id="collapse-notes" visible>
          <b-table class="mt-2 small" striped head-variant="dark" table-variant="secondary" :items="noteItems" :fields="noteFields" :sort-by.sync="noteSortBy" :sort-desc.sync="noteSortDesc" :sort-compare="sortCompare" primary-key="date_time">
            <template #cell(actions)="data">
              <b-button-group aria-label="Actions" size="sm">
                <b-button v-if="canEditNotes && !data.item.locked" variant="secondary" title="Delete" @click="actionDeleteNote(data.item)"><b-icon icon="trash"></b-icon></b-button>
              </b-button-group>
            </template>
            <template #cell(note)="data">
              <pre>{{data.item.note}}</pre>
            </template>
            <template #cell(timestamp)="data">
              {{new Date(data.item.timestamp).toLocaleString(locale)}}
            </template>
          </b-table>
        </b-collapse>
      </b-list-group-item>
      <b-list-group-item v-if="showStudyNotes && canEditNotes" class="bg-dark text-light">
        <b-container>
          <b-row>
            <b-col cols="2"><label>New Note:</label></b-col>
            <b-col cols="10"><b-form-textarea :state="Boolean(newNote)" class="bg-light" id="newNote" placeholder="Enter new note..." v-model="newNote"></b-form-textarea></b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <div class="float-right">
                <b-button @click="uploadNote" :disabled="newNote==''" variant="secondary">Add New Note</b-button>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import permissions from '../common/permissions'
import webServices from '../common/webServices'

export default {
  name: 'patientHistory',
  components: {
  },
  props: {
    mode: {
      type: String,
      default: "All"
    },
  },
  data() {
    return {
      acceptTypes: ".doc, .docx, .jpg, .jpeg, .pdf, .png",
      buttonSize: "sm",
      documentFileI: 0,
      documentFiles: [],
      documentFileSize: 0,
      documentFileUploaded: 0,
      documentDesc: '',
      documentItems: [],
      documentFields: [ 
        { key: 'actions', label: '', sortable: false },
        { key: 'description', label: 'Description', sortable: true },
        { key: 'timestamp', label: 'Date/Time', sortable: true },
      ],
      docSortBy: 'timestamp',
      docSortDesc: true,
      isUploading: false,
      newNote: '',
      noteItems: [],
      noteFields: [ 
        { key: 'actions', label: '', sortable: false },
        { key: 'user_full_name', label: 'By', sortable: false },
        { key: 'timestamp', label: 'Date/Time', sortable: true },
        { key: 'note', label: 'Note', sortable: false },
      ],
      noteSortBy: 'timestamp',
      noteSortDesc: true,
      showDocuments: true,
      showNotes: true,
      entryToShow: webServices.getEmptyWorklistEntry(),
      warningMsg: ''
    }
  },
  computed: {
    activeStudyUid() {
      if ((this.$store.state.activeComponent == 'PatientHistory') || (this.$store.state.activeComponent == 'StudyNotes')) {
        return this.$store.state.activeStudyUid
      }
      return ''
    },
    entry() {
      var entry = this.$store.getters.worklistEntryForStudy(this.activeStudyUid)
      entry = (entry != null) ? entry : webServices.getEmptyWorklistEntry()
      return entry
    },
    canEditNotes() {
      return permissions.hasPermission(this.entryToShow.group, permissions.CAN_EDIT_NOTES)
    },
    canEditPatientHistory() {
      return permissions.hasPermission(this.entryToShow.group, permissions.CAN_EDIT_PATIENT_HISTORY)
    },
    canViewNotes() {
      return permissions.hasPermission(this.entryToShow.group, permissions.CAN_VIEW_NOTES)
    },
    canViewPatientHistory() {
      return permissions.hasPermission(this.entryToShow.group, permissions.CAN_VIEW_PATIENT_HISTORY)
    },
    locale() {
      return this.$store.state.locale
    },
    showPatientHistory() {
      return (this.mode !== "StudyNotesOnly")
    },
    showStudyNotes() {
      return (this.mode !== "PatientHistoryOnly")
    },
    title() {
      return webServices.getTitleForEntry(this.entry)
    },
    uploadLimitMb() {
      return this.$store.state.uploadLimitMb;
    },
    documentFileSizesOkay() {
      var okay = true
      for (var i=0; i<this.documentFiles.length; i++) {
        if (this.documentFiles[i].size > this.uploadLimitMb*1024*1024) {
          okay = false
          return
        }
      }
      return okay
    }
  },
  watch: {
    entry(newVal/*, oldval*/) {
      // Patient/study information may have changed in entry.
      //
      if ((this.$store.state.activeComponent == 'PatientHistory') ||
          (this.$store.state.activeComponent == 'StudyNotes') ||
          (this.$store.state.activeComponent == 'ImageViewer') ||
          (this.$store.state.activeComponent == 'PdfViewer')) {
        if ((newVal != null) && (newVal.study_uid == this.entryToShow.study_uid)) {
          this.entryToShow = newVal
        }
      }
    },
    activeStudyUid(newVal/*, oldVal*/) {
      if ((this.$store.state.activeComponent == 'PatientHistory') ||
          (this.$store.state.activeComponent == 'StudyNotes') ||
          (this.$store.state.activeComponent == 'ImageViewer') ||
          (this.$store.state.activeComponent == 'PdfViewer')) {
        const entry = this.$store.getters.worklistEntryForStudy(newVal)
        this.entryToShow = (entry != null) ? entry : webServices.getEmptyWorklistEntry()
        this.show()
      }
      else {
        this.entryToShow = webServices.getEmptyWorklistEntry()
      }
    }
  },
  methods: {
    show() {
      this.documentItems = []
      this.documentFileI = 0
      this.documentFiles = []
      this.documentDesc = ''
      this.noteItems = []
      this.newNote = ''
      this.warningMsg = ''
      this.isUploading = false
      if (this.entryToShow.patient_id != '') {
        this.$log.debug('reading patient history for patient_id='+this.entryToShow.issuer+'|'+this.entryToShow.patient_id)
        webServices.readPatientHistory(this.entryToShow)
        .then(response => {
          // Make sure response is still valid for current entryToShow
          //
          if ((response.patient_id == this.entryToShow.patient_id) && (response.issuer == this.entryToShow.issuer)) {
            this.$log.debug('Handling response for expected: patient_id='+response.issuer+'|'+response.patient_id)
            this.documentItems = response.documents
            this.noteItems = response.notes
          }
          else {
            this.$log.warn('Ignoring response for unexpected: patient_id='+response.issuer+'|'+response.patient_id)
          }
        })
        .catch(error => {
          this.$log.error(`Error reading patient history: ${error.message}`)
          this.warningMsg = "Error reading patient history"
          this.$refs.warningDialog.show()
        })
      }
    },
    actionDeleteDocument(documentItem) {
      this.$log.debug("Delete document: "+documentItem.patient_history_doc_id)
      var answer = window.confirm('Do you really want to delete the document added by '+documentItem.user_full_name+'?')
      if (answer) {
        webServices.deletePatientHistoryDocument(this.entryToShow, documentItem.patient_history_doc_id)
        .then((/*response*/) => {
          this.$log.debug("Deleted document")
          webServices.readWorklist()
          this.show()
        })
        .catch(err => {
          this.$log.error("Error deleting document: "+err.message)
          this.warningMsg = "Error deleting document"
          this.$refs.warningDialog.show()
        })
      }
    },
    actionDeleteNote(noteItem) {
      this.$log.debug("Delete note: "+noteItem.patient_history_note_id)
      var answer = window.confirm('Do you really want to delete the note added by '+noteItem.user_full_name+'?')
      if (answer) {
        webServices.deletePatientHistoryNote(this.entryToShow, noteItem.patient_history_note_id)
        .then((/*response*/) => {
          this.$log.debug("Deleted note")
          webServices.readWorklist()
          this.show()
        })
        .catch(err => {
          this.$log.error("Error deleting note: "+err.message)
          this.warningMsg = "Error deleting note"
          this.$refs.warningDialog.show()
        })
      }
    },
    actionViewDocument(documentItem){
      this.$log.debug("View document: "+documentItem.patient_history_doc_id)
      let docUrl = webServices.patientHistoryDocumentUrl(this.entryToShow, documentItem.patient_history_doc_id)
      this.$log.debug('Direct link to document: ' + docUrl)
      switch(documentItem.filetype.toUpperCase()) {
        case 'GIF':
        case 'JPG':
        case 'JPEG':
        case 'PNG':
          this.$store.commit('changePatientHistoryDocId', documentItem.patient_history_doc_id)
          this.$store.commit('changeActiveComponent', 'ImageViewer')
          break;
        case 'DOC':
        case 'DOCX':
        case 'PDF':
          this.$store.commit('changePatientHistoryDocId', documentItem.patient_history_doc_id)
          this.$store.commit('changeActiveComponent', 'PdfViewer')
          break;
        default:
          this.$refs.documentLauncher.href = docUrl;
          this.$refs.documentLauncher.click()
          break;
      }
    },
    handleClose() {
      this.$store.commit('changeActiveComponent', '')
    },
    handleWarningOK() {
      this.warningMsg = ''
    },
    sortCompare(aRow, bRow, key /*, sortDesc, formatter, compareOptions, compareLocale*/) {
      if (key == 'timestamp') {
        const aD = new Date(aRow['timestamp'])
        const bD = new Date(bRow['timestamp'])
        // +TODO+ Handle ISO date format
        return bD - aD
      }
      else {
        // Fallback to default sort routine.
        //
        return false;
      }
    },
    uploadOneDocument() {
      this.isUploading = true;
      const documentFile = this.documentFiles[this.documentFileI]
      var documentFileName = documentFile.name
      this.documentFileSize = documentFile.size
      this.$log.debug("documentFileSize="+this.documentFileSize)
      this.documentFileUploaded = 0
      var description = documentFileName
      description += (this.documentDesc != '') ? ': ' + this.documentDesc : ''
      var reader = new FileReader();
      var thisForReader = this
      reader.onerror = function(/*file*/) {
        thisForReader.$log.error("Error reading document file="+documentFileName)
        reader.abort();
        thisForReader.warningMsg = "Error reading document file="+documentFileName
        thisForReader.$refs.warningDialog.show()
        thisForReader.isUploading = false;
      };

      reader.onload = function(/*file*/) {
        thisForReader.$log.debug("Read document file="+documentFileName)
        webServices.updatePatientHistoryDocuments(thisForReader.entryToShow, new Blob([reader.result]), documentFileName, description, (progressEvent) => {
          thisForReader.documentFileUploaded = progressEvent.loaded
        })
        .then((/*response*/) => {
          thisForReader.$log.debug("Uploaded document="+documentFileName)
          webServices.readWorklist()
          if (thisForReader.documentFiles.includes(documentFile)) {
            thisForReader.documentFileI++
            if (thisForReader.documentFileI < thisForReader.documentFiles.length) {
              thisForReader.uploadOneDocument()
            }
            else {
              // Done!
              //
              thisForReader.isUploading = false
              thisForReader.show()
            }
          }
          else {
            // Response is from a prior upload - ignore
            //
            thisForReader.$log.debug("Ignoring response for previous upload request, document="+documentFileName)
          }
        })
        .catch(err => {
          thisForReader.$log.error("Error updating document: "+err.message)
          thisForReader.warningMsg = "Error uploading document file="+documentFileName
          thisForReader.$refs.warningDialog.show()
          thisForReader.isUploading = false
        })
        .finally(() => {
        })
      }
      reader.readAsArrayBuffer(documentFile)
    },
    uploadDocuments() {
      // Start uploading the documents
      //
      this.documentFileI = 0
      this.uploadOneDocument()
    },
    uploadNote() {
      webServices.updatePatientHistoryNotes(this.entryToShow, this.newNote)
      .then((/*response*/) => {
        this.$log.debug("Uploaded new note")
        webServices.readWorklist()
        this.show()
      })
      .catch(err => {
        this.$log.error("Error updating notes: "+err.message)
        this.warningMsg = "Error uploading new note"
        this.$refs.warningDialog.show()
      })
    }
  }
};
</script>
<style scoped>
pre { 
  max-height: 200px;
  overflow-y: auto;
  white-space: pre-wrap;
}
</style>